<template>
  <menikheim-layout>
    <template #content>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1><span class="text-highlight">&Uuml;</span>ber 50 Jahre Qualität uns Service</h1>
            <img class="rounded imgSpace" src="@/assets/images/s_Menikheim_1.jpg" alt="Steffen Menikheim" style="max-width:90%" />
            <h2><span class="text-highlight">B</span>auen heißt Vertrauen</h2>
            <p>Wer sich entschließt, sein Haus umzubauen, es energetisch zu sanieren oder gar ein neues Zuhause zu schaffen, ist bei uns gut aufgehoben. Mit unserer Erfahrung aus über 40 Jahren wissen wir, was Bauherren bewegt. Deshalb bieten wir Ihnen zu unserem Fachwissen und handwerklichen Können vor allem Zuverlässigkeit, Termintreue und eine hohe Flexibilität an. Mit über 60 qualifizierten Mitarbeiterinnen und Mitarbeitern sind wir dafür da, Ihre Wünsche zu verwirklichen. Mit unserer motivierten Mannschaft sind wir auch in der Lage große Objekte fertig zu stellen.</p>
            <h2><span class="text-highlight">D</span>as sind wir: Allrounder!</h2>
            <p>Wir sind Ihr kompetenter Ansprechpartner für sämtliche Putz- und Stuckarbeiten im Innen- und Außenbereich. Wir übernehmen Arbeiten am Neu- und Altbau oder bauen Ihr Dach aus. Außerdem haben wir uns in den zurückliegenden Jahren auf Wärmedämm-Verbund-Systeme spezialisiert. Wenn Sie wollen, erhalten Sie bei uns als Allrounder von der einfachen Umbaumaßnahme bis zur aufwendigen energetischen Sanierung alles aus einer Hand. An unserem Standort in der Brühlstraße in Rudersberg verfügen wir über ein großes Lager und über 10.000 Quadratmeter Gerüst. Aber auch ohne Gerüst können wir ganz schnell hoch hinaus: Unsere Gelenk-Teleskop-Arbeitsbühne mit Raupenfahrgestell erreicht ohne großen Aufwand eine Höhe von bis zu 14 Metern. Unser Maschinenpark ist immer auf dem aktuellsten Stand. Mit unserem eigenen Silofahrzeug können wir Ihre Baustelle jederzeit beliefern. Aber auch das ist uns wichtig: Unser gesamter Fuhrpark hat die grüne Plakette. Denn modernes Bauen und Umweltschutz gehören für uns einfach zusammen.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h1><span class="text-highlight">U</span>nsere Firmenchronik</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <img src="@/assets/images/grundstein-3492c0b8.jpg" class="rounded imgSpace" />
            <h2>1974 <span class="text-highlight">|</span> Der Grundstein</h2>
            <p>Die Firma wurde im Jahr 1974 von Stuckateurmeister Jürgen Menikheim gegründet. In dieser Zeit hatte die Bauhochkonjunktur deutlich nachgelassen und eine Neugründung war nicht einfach. Doch das fachliche Können des Unternehmers und sein “schwäbischer Fleiß” haben den Stuckateurbetrieb rasch bodenständig werden lassen. Seine Ehefrau hat ihn dabei tatkräftig unterstützt. Neben der Erziehung der drei Kinder hat sie die Büroarbeiten übernommen.</p>
          </div>
        </div>
        <div class="row"><div class="col-12"><hr /></div></div>
        <div class="row">
          <div class="col-12">
            <img src="@/assets/images/uebergabe-3ab772d7.jpg" class="rounded imgSpace" />
            <h2>1999 <span class="text-highlight">|</span> Die Übergabe</h2>
            <p>Nach 25 Jahren wurde der Betrieb im Jahre 1999 an den Sohn Steffen Menikheim übergeben. Er hatte bereits 1992 die Meisterprüfung im Stuckateurhandwerk erfolgreich abgelegt. Vor der Übernahme arbeitete er als Meister in einem anderen Stuckateurgeschäft und danach einige Jahre im elterlichen Betrieb, wo er sich ein umfangreiches Fachwissen aneignen konnte.</p>
          </div>
        </div>
        <div class="row"><div class="col-12"><hr /></div></div>
        <div class="row">
          <div class="col-12">
            <img src="@/assets/images/neues_firmengelaende-c39279ae.jpg" class="rounded imgSpace" />
            <h2>2003 <span class="text-highlight">|</span> Das neue Firmengelände</h2>
            <p>Aus Expansionsgründen wurde der Betrieb im Jahre 2003 von der Daimlerstraße in die Brühlstraße verlegt, wo ein neues Firmengelände erworben wurde. Wir führen Putz-, Stuck-, Trockenbau- und Estricharbeiten fachmännisch aus. Die Zahl der Mitarbeiter ist nunmehr auf 43 angestiegen.</p>
          </div>
        </div>
        <div class="row"><div class="col-12"><hr /></div></div>
        <div class="row">
          <div class="col-12">
            <img src="@/assets/images/silosteller-08c491f1.jpg" class="rounded imgSpace" />
            <h2>2010 <span class="text-highlight">|</span> Flexibel mit dem eigenen Silosteller</h2>
            <p>Moderne Baustellentechnik ist Voraussetzung für die reibungslose Abwicklung unserer Aufträge. Deshalb haben wir 2010 in einen eigenen Silosteller investiert. Ungewöhnlich für ein Unternehmen unserer Größenordnung – aber eine umweltfreundliche Möglichkeit, auch große Mengen an Material direkt und auf kurzen Wegen anzuliefern. Das zahlt sich auch für Bauherren aus, mit denen wir zusammenarbeiten: Mit dem Lkw aus dem eigenen Fuhrpark und eigenen Silobehältern können wir schnell und flexibel auf die speziellen Gegebenheiten der Baustellen reagieren. Bei garantiert kurzen Standzeiten setzen wir den Silosteller für Innen- und Außenputz- sowie Fließestrich-Arbeiten ein.</p>
          </div>
        </div>
        <div class="row"><div class="col-12"><hr /></div></div>
        <div class="row">
          <div class="col-12">
            <img src="@/assets/images/anbau-7f7342d4.jpg" class="rounded imgSpace" />
            <h2>2013 <span class="text-highlight">|</span> Wir wachsen in der Brühlstraße</h2>
            <p>Seit die Firma Menikheim besteht, garantiert ein beständiges, moderates Wachstum den Unternehmenserfolg. Daran hat sich auch nach 39 Jahren nichts geändert. 2013 haben wir beschlossen, unser zu eng gewordenes Bürogebäude in der Brühlstraße zu erweitern. Verwaltung und Ausstellung bekommen mehr Platz, damit wir unsere Kunden in angenehmer Atmosphäre umfassend beraten können.</p>
          </div>
        </div>
        <div class="row"><div class="col-12"><hr /></div></div>
        <div class="row">
          <div class="col-12"><h1><span class="text-highlight">M</span>itarbeiterveranstaltungen</h1></div>
        </div>
        <div class="row">
          <EventComponent v-for="(event, key) in getEvents" :key="event.caption" :event="event" :last="isLast(key)" />
        </div>
      </div>
    </template>
  </menikheim-layout>
</template>

<script>
import MenikheimLayout from "@/layouts/MenikheimLayout.vue";
import EventComponent from "@/components/EventComponent.vue";

export default {
  name: "FirmaUnsPage",
  components: {
    MenikheimLayout,
    EventComponent
  },
  props: {
    mandantId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getEvents() {
      return this.$store.getters.getEvents;
    }
  },
  methods: {
    isLast(i) {
      return i != Object.keys(this.$store.getters.getEvents).length - 1;
    }
  },
  data() {
    return {

    };
  },
};
</script>

<style scoped>
.imgSpace {
  float:left;
  margin: 15px;
}
</style>
