<template>
   <menikheim-layout>
    <template #content>
      <div class="container">
    <div class="row">
      <div class="col-12 mb-5">
        <h1>Menikheim <span class="text-highlight">Stuck</span> GmbH</h1>
      </div>
      <div class="col-12 col-md-4">
        <h3><span class="text-highlight">A</span>nschrift</h3>
        <p>Brühlstrasse 20<br>73635 Rudersberg</p>
        <h3><span class="text-highlight">T</span>elefon / Telefax</h3>
        <p>Telefon: 07183 / 8428<br>Telefax: 07183 / 7489</p>
        <h3><span class="text-highlight">E</span>-Mail</h3>
        <p><a href="mailto:info@menikheim-stuck.de">info@menikheim-stuck.de</a></p>
      </div>
      <div class="col-12 col-md-8">
        <div class="container">
          <div class="row mt-5">
            <div class="col-12 col-md-6">

            </div>
            <div class="col-12 col-md-6">
              <h3>Frau <span class="text-highlight">D</span>aniela Nolandt</h3>
              <p>Geschäftszeiten: Mo-Fr 7.30 bis 17.00 Uhr</p>
              <p>Telefon: 07183 / 8428<br /><a href="mailto:info@menikheim-stuck.de">info@menikheim-stuck.de</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  </menikheim-layout>
</template>

<script>
import MenikheimLayout from "@/layouts/MenikheimLayout.vue";

export default {
  name: "KontaktPage",
  components: {
    MenikheimLayout
  },
}
</script>

<style scoped>

</style>